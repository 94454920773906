import React, { useState } from "react";
import { navigate, graphql } from "gatsby";
import Layout from "../components/layout";
import { API } from "aws-amplify";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import { getProfile, updateProfile } from "../queries.js";
import { AmplifyAuthenticator, AmplifySignUp } from "@aws-amplify/ui-react";
import { Translations } from "@aws-amplify/ui-components";
import { useIntl } from "react-intl";

const BoutiqueScanTemplate = ({ data, pageContext }) => {
  const intl = useIntl();
  const locale = pageContext.locale;
  const originalPath = pageContext.originalPath;
  const [authState, setAuthState] = useState();
  const [user, setUser] = useState();

  const boutique = data.mdx.frontmatter;

  React.useEffect(() => {
    onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
    });
  }, []);

  React.useEffect(() => {
    if (authState === AuthState.SignedIn && user) {
      API.graphql({
        query: getProfile,
      }).then((re) => {
        let { id, visits } = re.data.getProfile;
        visits = visits === null ? [] : JSON.parse(visits);

        if (visits.indexOf(boutique.name) > -1) {
          navigate("/passport/");
        }

        API.graphql({
          query: updateProfile,
          variables: {
            updateprofileinput: {
              id,
              visits: JSON.stringify([...visits, boutique.name]),
            },
          },
        }).then((re) => {
          navigate("/passport/");
        });
      });
    }
  }, [authState, user, boutique.name])

  return (
    <Layout locale={locale} originalPath={originalPath}>
      <AmplifyAuthenticator>
        <AmplifySignUp
          slot="sign-up"
          usernameAlias="email"
          formFields={[
            {
              type: "name",
              label: intl.formatMessage({ id: "form-name" }),
              placeholder: "Paul Becker",
              required: true,
            },
            {
              type: "email",
              label: Translations.EMAIL_LABEL,
              placeholder: Translations.EMAIL_PLACEHOLDER,
              required: true,
            },
            {
              type: "password",
              label: Translations.PASSWORD_LABEL,
              placeholder: Translations.PASSWORD_PLACEHOLDER,
              required: true,
            },
          ]}
        />
      </AmplifyAuthenticator>
    </Layout>
  );
};

export default BoutiqueScanTemplate;

export const query = graphql`
  query ($locale: String!, $originalSlug: String!) {
    mdx(
      fields: { locale: { eq: $locale } }
      frontmatter: { slug: { eq: $originalSlug } }
    ) {
      frontmatter {
        id
        language
        slug
        name
      }
    }
  }
`;
